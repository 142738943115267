<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Campaign Error Logs - Counts</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row listing">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                                </div>

                                <div class="form-group mr-1">
                                    <input type="time" v-model="filter.start" class="form-control date" placeholder="To"/>
                                </div>
                                <div class="form-group mr-1 mt-3 p-1">
                                    <p>&#9866;</p>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                                </div>

                                <div class="form-group mr-1">
                                    <input type="time" v-model="filter.end" class="form-control date" placeholder="To"/>
                                </div>

                                <div class="form-group mr-1  pl-2">
                                    <select class="form-control" v-model="filter.period">
                                        <option selected :value="undefined">---Daily---</option>
                                        <option v-for="item in period" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>
                                <div class="form-group mr-1">
                                    <select class="form-control" v-model="filter.provider">
                                        <option selected :value="undefined">---Any Provider---</option>
                                        <option v-for="item in providers" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>

                                <div class="form-group mr-1">
                                    <select class="form-control" v-model="filter.type">
                                        <option selected :value="undefined">---Any Type---</option>
                                        <option v-for="item in types" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>

                                <div class=" mr-1 pl-2 pr-2" >
                                    <b-form-checkbox v-model="filter.geo" name="check-button" switch>
                                        Group By Geo
                                    </b-form-checkbox>
                                </div>
                                <!--                            <div class="form-group mr-1">-->
                                <!--                                <select v-model="filter.country" @change="Getstates();" class="form-control">-->
                                <!--                                    <option value="" selected>&#45;&#45; Any country &#45;&#45;</option>-->
                                <!--                                    <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>-->
                                <!--                                </select>-->
                                <!--                            </div>-->
                                <!--                            <div class="form-group mr-1">-->
                                <!--                                <div class="form-group mr-1" :v-if="states !== null">-->
                                <!--                                    <ejs-multiselect   id='multiselectcity' v-model="filter.state"-->
                                <!--                                                       :allowFiltering="true" placeholder="Select City"-->
                                <!--                                                       :dataSource='states'-->
                                <!--                                                       :change='Getcities'-->



                                <!--                                    >-->

                                <!--                                    </ejs-multiselect>-->
                                <!--                                </div>-->
                                <!--                                <select v-model="filter.state" @change="Getcities();" class="form-control">-->
                                <!--                                    <option value="" selected>&#45;&#45; Any state &#45;&#45;</option>-->
                                <!--                                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>-->
                                <!--                                </select>-->
                                <!--                            </div>-->
                                <!--                            <b-col cols="2"  >-->
                                <!--                                <div class="form-group mr-1" :v-if="city.city_name !== null">-->
                                <!--                                    <ejs-multiselect  id='multiselectcity' v-model="filter.city" :fields="city" :allowFiltering="true" placeholder="Select City" :dataSource='cities'></ejs-multiselect>-->
                                <!--                                </div>-->
                                <!--                            </b-col>-->
                                <!----
                                 <div class="form-group mr-1">
                                    <input type="text" v-model="filter.limit" class="form-control" placeholder="Limit"/>
                                </div>

                                ----->
                                <button type="button"  @click="load" class="btn searchBtn text-white mr-1" style="background-color: #383838"><i class="fa fa-search" title="Search"></i> <span>Search Errors</span></button>
                                <button type="button" @click="exportToExcel"  class="btn btn-success">Export</button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grids"
                        :dataSource="items"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :groupSettings='groupSettings'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                        :dataBound='dataBound'

                    >
                        <e-columns>
                            <e-column field='provider' headerText='Provider'></e-column>
                            <e-column field='date' headerText='Date'></e-column>
                            <e-column field='time_frame' headerText='Date Time'></e-column>
                            <e-column field='type' headerText='Type'></e-column>
                            <e-column field='country'  headerText='Country'></e-column>
                            <e-column field='state'  headerText='State'></e-column>
                            <e-column field='city'  headerText='City'></e-column>
                            <e-column field='message' minWidth='8' width='800' headerText='Message'></e-column>
                            <e-column field='count' headerText='Error Count'></e-column>
                            <e-column field='attempts' headerText='Attempts'></e-column>
                            <e-column field='percentage' headerText='Percentage'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert"
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
// import VSelect from 'vue-select'
import Vue from "vue";

Vue.use(GridPlugin);

export default {
    name: "errors",
    props:['user'],
    components:{
    },
    data() {
        return {
            city:{text: 'city_name', value: 'city_name'},
            items: [],
            pageNumber:1,
            providers:[],
            perPage:25,
            pageSettings: {pageSize: 25},
            types:['error', 'proxy', 'fail'],
            period:[ 'Hourly'],
            type:'',
            Dataset: [],
            countries:[],
            states:[],
            cities:[],
            filter:{
                start: "00:00",
                end: "23:59",
                limit: 1000000,
                country:'',
                state:'',
                city:'',
                geo:false
            },
            timeSelector:['12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'],
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            col:[{field:'provider',  headerText:'Provider'}
                ,{field:'date',  headerText:'Date'},{field:'time_frame',  headerText:'Date Time'},
                {field:'type',  headerText:'Type'},{field:'message', headerText:'Message'},
                {field:'count', headerText:'Error Count'},
                {field:'attempts', headerText:'Attempts'},  {field:'percentage', headerText:'Percentage'},
            ]
        }
    },

    async created() {

        this.loadProviders();
        this.loadcountries();
        this.loadDate();
        // this.load();
        this.$root.preloader = false;


    },

    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize
        ]
    },


    methods: {
        dataBound: function() {
            // this.$refs.grid.autoFitColumns(['message']);
        },
        hide: function () {
            this.$refs.grid.hideColumns(['Country', 'State','City']);
            //if(!this.filter.country && !this.filter.state && !this.filter.city) this.$refs.grid.hideColumns(['Country', 'State','City']);
           /* if(this.filter.country  && !this.filter.state && !this.filter.city) this.$refs.grid.showColumns(['Country']);
            if(!this.filter.country  && this.filter.state && !this.filter.city) this.$refs.grid.showColumns(['State']);
            if(!this.filter.country  && !this.filter.state && this.filter.city) this.$refs.grid.showColumns(['City']);
            if(this.filter.country  && this.filter.state && !this.filter.city) this.$refs.grid.showColumns(['Country','State']);
            if(this.filter.country  && this.filter.state && this.filter.city) this.$refs.grid.showColumns(['Country','State','City']);*/
            if(this.filter.geo){
                this.$refs.grid.showColumns(['Country','State','City']);
            }
            this.$refs.grid.refresh()

        },
        loadcountries: function(){

            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
                this.hide();
            }.bind(this));
        },
        Getstates: function(){
            this.states = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },

        Getcities: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;
            if(this.filter.state) request.filters.subdivision_1_iso_code = this.filter.state;

                return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params: request}).then(function (resp) {
                    //Store the stats
                    this.cities = (resp.data) ? resp.data.data : [];
                }.bind(this));

        },

        loadDate: function () {
            //Load the campaign
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            const dateString = [year, month, day].join('-');
            this.filter.from = dateString;
            this.filter.to = dateString;
        },

        load: async function() {
            try{
                this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey, sSearch:null};
                this.Dataset = []
                request.filters = this.filter
                let response = await axios.get(`${this.$root.serverUrl}/admin/reports/campaign/error-count`, {params:request})
                console.log(response);
                let items = response.data.data;
                this.report = [];

                if(!response.data.data){

                    this.$root.preloader = false;
                    await Swal('Error!!', response.data.error,'error')
                }else{
                    for (let i in items) {
                        this.report.push({
                            type:items[i].type,
                            time_frame:  items[i].timeFormat,
                            date: items[i].dateFormat,
                            provider:items[i].provider,
                            message:items[i].message,
                            count: items[i].count,
                            state: items[i].state,
                            country: items[i].country,
                            city: items[i].city,
                            attempts: items[i].attempts,
                            percentage:items[i].percentage + ' %',

                        })
                    }
                    // if(items.length === 0){
                    //     console.log('zero')
                    //     this.items = [];
                    // }
                    this.items = this.report;
                  //  console.log({items: items})
                    this.hide()
                    this.$root.preloader = false;
                    this.$forceUpdate();
                }

            }catch(e){
                console.log(e);
                this.$root.preloader = false;
            }
        },

        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];

            }.bind(this));
        },

        getDate: function(dateTime){
            let d = new Date(dateTime),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day, year].join('/');
        },

        search() {

        },

        views() {

        },
        excel: async function (){
            try
            {
                this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey, sSearch:null};
                this.Dataset = []
                request.filters = this.filter
                let response = await axios.get(`${this.$root.serverUrl}/admin/reports/campaign/error-count`, {params:request,responseType: 'blob'})

                let excelExportProperties = {
                    dataSource: response.data.data,
                    fileName:"Campaign Error Count.xlsx"
                };
                this.$refs.grid.excelExport(excelExportProperties);
                this.$root.preloader = false;
            }catch(e){
                console.log(e);
                this.$root.preloader = false;
            }
        },
        getTimeFrame(dateTime) {
            const timeFrameMap = [
                '12 AM - 1AM',
                '1 AM - 2AM',
                '2 AM - 3AM',
                '3 AM - 4AM',
                '4 AM - 5AM',
                '5 AM - 6AM',
                '6 AM - 7AM',
                '7 AM - 8AM',
                '8 AM - 9AM',
                '9 AM - 10AM',
                '10 AM - 11AM',
                '11 AM - 12PM',
                '12 PM - 1PM',
                '1 PM - 2PM',
                '2 PM - 3PM',
                '3 PM - 4PM',
                '4 PM - 5PM',
                '5 PM - 6PM',
                '6 PM - 7PM',
                '7 PM - 8PM',
                '8 PM - 9PM',
                '9 PM - 10PM',
                '10 PM - 11PM',
                '11 PM - 12AM'
            ];
            let d = new Date(dateTime);
            return timeFrameMap[d.getHours()];
        },

        exportToExcel: async function() {
            let fileName = `Export Campaign Error Logs Count ${this.filter.from} to ${this.filter.to}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.items,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },
    }

}
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>
